import React from 'react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { debounce } from 'lodash'
import lbdbService from '../services/lbdb-service'
import { Link } from 'react-router-dom'
import { findInvRank, invValueColor, findCharRank, charValueColor } from '../utils/scoreStyle'
import { timeAgo } from '../utils/timeAgo'

function Leaderboards() {

    // return (
    //   <div className='root' style={{background: 'none'}}>
    //       <div className='app'>
    //           <h2 style={{fontWeight: 700, borderBottom: '1px solid #474239', paddingBottom: '8px'}}>🏆 LEADERBOARDS</h2>
    //           <div style={{display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', height: '70vh', padding: '32px', textAlign: 'center'}}>
    //               <img alt="" src={require('../assets/underconstruction.png')} style={{width: '256px'}}/>
    //               <h3>This page is almost ready!</h3>
    //               <p>We are still collecting data from all of you! <a href="/search">Submit</a> your or your friend's data to help us release this page faster!</p>
    //               <p>If you signed up very early, please refresh your account to stay in sync with our new system.</p>
    //           </div>
    //       </div>
    //   </div>
    // )

    const [searchParams, setSearchParams] = useSearchParams()
    const [leaderboard, setLeaderboard] = useState(null)
    const [lbType, setLbType] = useState(searchParams.get('lb') ? searchParams.get('lb') !== 'lbinvscore' ? 1 : 0 : 0)
    const [lastUpdated, setLastUpdated] = useState(null)
    const [loadingState, setLoadingState] = useState(false)

    const getLb = debounce(async (lbName, page) => {
        try {
            const lbVersion = await lbdbService.getVersion(lbName)
            setLastUpdated(lbVersion)
            const lbData = await lbdbService.lbData(lbName, page)
            setLeaderboard(lbData)
        }
        catch (e) {
            alert(e.response.data.error)
        }
        setLoadingState(false)
    }, 500)

    const renderPlayers = leaderboard ? leaderboard.map((curr, index) => {

        let uidRegion = 'China'
        const regionCode = parseInt(curr.uid.toString()[0])
        if (regionCode === 6) { uidRegion = 'America' }
        else if (regionCode === 7) { uidRegion = 'Europe' }
        else if (regionCode === 8) { uidRegion = 'Asia' }
        else if (regionCode === 8) { uidRegion = 'TW,HK,MO' }

        return (
            <div key={`lb${index}`} className='leaderboard-player'>
                <div className='leaderboard-player--part'>
                    <b style={{ minWidth: '32px', textAlign: 'center' }}>#{curr.rank + 1}</b>
                    <img alt='' src={(() => {
                        try {
                            return require(`../assets/pfp/${curr.pfp}.png`);
                        } catch (e) {
                            return require(`../assets/pfp/no-texture.png`);
                        }
                    })()} style={{ width: '38px', height: '38px', borderRadius: '99px', border: '1px solid #474239', margin: 0 }} />
                    <Link style={{ color: '#a4a4a4' }} to={`/uid/${curr.uid}`}>{curr.displayName}</Link>
                </div>

                <div className='leaderboard-player--part'>
                    <b style={{ width: '85px', textAlign: 'center' }}>{curr.invScore ? curr.invScore.toFixed(1) : curr.totalRs ? curr.totalRs.toFixed(1) : '0'}</b>
                    <b style={{ width: '85px', textAlign: 'center', color: lbType === 0 ? invValueColor(curr.invScore) : charValueColor(curr.totalRs) }}>{lbType === 0 ? findInvRank(curr.invScore) : findCharRank(curr.totalRs)}</b>
                    <p style={{ width: '85px', textAlign: 'center', color: '#a4a4a4' }}>{uidRegion}</p>
                    <p style={{ width: '85px', textAlign: 'center', color: '#a4a4a4' }}>{curr.uid}</p>
                </div>
            </div>
        )
    }) : ''

    const swapPage = (value) => {
        if (value < 0 || value > 9 || value === parseInt(searchParams.get('page'))) { return }
        else if (isNaN(value)) {
            const newParams = new URLSearchParams(searchParams)
            newParams.set('page', 0)
            setSearchParams(newParams)
        }
        else {
            window.scrollTo(0, 0)
            setLeaderboard(null)
            setLoadingState(true)
            const newParams = new URLSearchParams(searchParams)
            newParams.set('page', value)
            setSearchParams(newParams)
        }
    }

    const pageSwapper = () => {
        return (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <button
                    onClick={loadingState ? () => { } : () => swapPage(0)}
                    style={{ cursor: loadingState ? 'wait' : 'pointer' }}
                    className='lb-next-btn'
                >❮❮</button>
                <button
                    onClick={loadingState ? () => { } : () => swapPage(parseInt(searchParams.get('page')) - 1)}
                    style={{ cursor: loadingState ? 'wait' : 'pointer' }}
                    className='lb-next-btn'
                >❮ prev</button>
                <span style={{ width: '60px', textAlign: 'center' }}>Page: {isNaN(parseInt(searchParams.get('page'))) ? '0' : parseInt(searchParams.get('page')) + 1}</span>
                <button
                    onClick={loadingState ? () => { } : () => swapPage(parseInt(searchParams.get('page')) + 1)}
                    style={{ cursor: loadingState ? 'wait' : 'pointer' }}
                    className='lb-next-btn'
                >next ❯</button>
                <button
                    onClick={loadingState ? () => { } : () => swapPage(9)}
                    style={{ cursor: loadingState ? 'wait' : 'pointer' }}
                    className='lb-next-btn'
                >❯❯</button>
            </div>
        )
    }

    const swapLb = (value) => {
        window.scrollTo(0, 0)
        setLeaderboard(null)
        const newParams = new URLSearchParams(searchParams)
        newParams.set('lb', value)
        setSearchParams(newParams)
        // eslint-disable-next-line
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Leaderboards | scoremyrelic'
        const newParams = new URLSearchParams(searchParams)
        newParams.set('page', 0)
        setSearchParams(newParams)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getLb(searchParams.get('lb') ? searchParams.get('lb') : 'lbinvscore', searchParams.get('page'))
        // eslint-disable-next-line
    }, [searchParams])

    // useEffect(() => {

    //   // eslint-disable-next-line
    // }, [searchParams])

    return (
        <div className='root' style={{ background: 'none' }}>
            <div className='app' style={{ minHeight: '75vh' }}>
                <h2 style={{ fontWeight: 700, borderBottom: '1px solid #474239', paddingBottom: '8px' }}>🏆 LEADERBOARDS</h2>
                <p>Global leaderboards for accounts that have been added to our system.</p>
                <p style={{ color: '#848484', fontSize: '12px', marginBottom: '36px' }}><b>Note: </b>Only leaderboards for popular characters exist. When a character becomes popular enough, they will be added. Additionally, only accounts that have been searched on this website are ranked. Therefore, there may exist accounts unbeknownst to us that rank higher than the ones shown here. Leaderboards automatically refresh on our servers in 1 hour intervals. Changes to your profile are only shown on the leaderboards after the 1 hour refresh interval. Leaderboards will not sync with in-game progress during Hoyoverse maintenance hours. Changes to the meta may affect your leaderboard position.</p>

                <div style={{ display: 'flex', height: '100px', margin: '16px 0px 16px 0px', gap: '16px' }}>
                    <button
                        className={`leaderboard-selector ${lbType === 0 ? 'selected' : ''}`}
                        onClick={() => {
                            setLbType(0)
                            swapLb('lbinvscore')
                        }}
                    >
                        <img alt='' src={require('../assets/lb-btn0.png')} style={{ position: 'absolute', width: '100%', minWidth: '400px', zIndex: -1 }} />
                        <h1>All Characters</h1>
                    </button>

                    <button
                        className={`leaderboard-selector ${lbType === 1 ? 'selected' : ''}`}
                        onClick={() => {
                            setLbType(1)
                            swapLb(1220)
                        }}
                    >
                        <h1>Single Character</h1>
                        <img alt='' src={require('../assets/lb-btn1.png')} style={{ position: 'absolute', width: '100%', minWidth: '400px', zIndex: -1 }} />
                    </button>
                </div>
                <div className="main-container" style={{ flexDirection: 'column', gap: '4px', padding: '16px', overflowX: 'auto' }}>
                    <div style={{ minWidth: '650px', display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        <div style={{ display: 'flex', gap: '8px', alignItems: 'flex-start', justifyContent: 'space-between', margin: 0 }}>
                            <div style={{ display: 'flex', gap: '8px' }}>

                                <img style={{ height: '50px', width: '50px', margin: 0, borderRadius: '8px', backgroundColor: 'black' }} src={(() => {
                                    try {
                                        return require(`../assets/pfp/20${searchParams.get('lb')}.png`);
                                    } catch (e) {
                                        return require(`../assets/pfp/no-texture.png`);
                                    }
                                })()} alt='' />

                                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center' }}>
                                    {lbType === 0 &&
                                        <>
                                            <select className='substat-dropdown' style={{ margin: '0', maxWidth: '200px', minWidth: '200px', maxHeight: '28px' }}>
                                                <option value='1'>All Characters</option>
                                            </select>
                                            <span style={{ color: '#848484', fontSize: '11px' }}>Last updated: {lastUpdated === null ? '' : `${timeAgo(lastUpdated)}`}</span>
                                        </>
                                    }
                                    {lbType === 1 &&
                                        <>
                                            <select value={searchParams.get('lb')} onChange={e => swapLb(e.target.value)} className='substat-dropdown' style={{ margin: '0', maxWidth: '200px', minWidth: '200px', maxHeight: '28px' }}>
                                                <option value='1220'>Feixiao</option>
                                                <option value='1221'>Yunli</option>
                                                <option value='1310'>Firefly</option>
                                                <option value='1308'>Acheron</option>
                                                <option value='1307'>Black Swan</option>
                                                <option value='1305'>Dr. Ratio</option>
                                                <option value='1112'>Topaz and Numby</option>
                                                <option value='1212'>Jingliu</option>
                                                <option value='1213'>Imbibitor Lunae</option>
                                                <option value='1205'>Blade</option>
                                                <option value='1005'>Kafka</option>
                                                <option value='1204'>Jing Yuan</option>
                                                <option value='1003'>Himeko</option>
                                                <option value='1102'>Seele</option>
                                            </select>
                                            <span style={{ color: '#848484', fontSize: '11px' }}>Last updated: {lastUpdated === null ? '' : `${timeAgo(lastUpdated)}`}</span>
                                        </>
                                    }
                                </div>
                            </div>

                            {pageSwapper()}

                        </div>

                        <div className='leaderboard-player' style={{ background: 'none', padding: '4px 8px 4px 8px' }}>
                            <div></div>
                            <div className='leaderboard-player--part'>
                                <p style={{ width: '85px', textAlign: 'center' }}>Total Value</p>
                                <p style={{ width: '85px', textAlign: 'center' }}>Rating</p>
                                <p style={{ width: '85px', textAlign: 'center', color: '#a4a4a4' }}>Region</p>
                                <p style={{ width: '85px', textAlign: 'center', color: '#a4a4a4' }}>UID</p>
                            </div>
                        </div>

                        {renderPlayers}
                        {leaderboard === null &&
                            <>
                                <div className='leaderboard-player' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ margin: 0, height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Loading players...</p>
                                </div>
                            </>
                        }
                        {leaderboard !== null && leaderboard.length === 0 &&
                            <>
                                <div className='leaderboard-player' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p style={{ margin: 0, height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>This page is empty.</p>
                                </div>
                            </>
                        }
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '16px 0px 16px 0px' }}>
                            <button
                                className='lb-next-btn'
                                onClick={() => window.scrollTo(0, 0)}>Back to top ⤴
                            </button>
                            {pageSwapper()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Leaderboards

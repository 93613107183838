import React from 'react'
import { getSignature } from '../../utils/signatureParse'
import userDbService from '../../services/userdb-service'
import { useState } from 'react'
import RefreshCooldown from './RefreshCooldown'
import { timeAgo } from '../../utils/timeAgo'

function ProfileBanner({ user, profileVersion }) {

    const signature = getSignature(user.signature)
    const [loading, setLoading] = useState(false)
    const handleUserRefresh = async () => {
        setLoading(true)
        try {
            await userDbService.refreshUser(user.uid)
            window.location.reload()
        } catch (e) {
            if (e.response.data.error === 'Too many refreshes. Please slow down.') {
                alert(e.response.data.error)
            }
            else {
                alert('Could not refresh profile. Either an internal error occurred, or the game is under maintenance right now.')
            }
            setLoading(false)
        }
    }

    const renderBadges = user.badges.map((curr, index) => {
        return (
            <div key={`userbadge${index}`} style={{ height: '100%', margin: '0px 8px 0px 0px', backgroundColor: curr.color, display: 'inline', borderRadius: '99px', padding: '2px 8px 4px 8px' }}>
                <span style={{ fontWeight: 600, fontSize: '12px', textTransform: 'uppercase' }}>{curr.text}</span>
            </div>
        )
    })

    return (
        <>
            <div className="profile-panel" style={{ marginTop: '16px', padding: 0, backgroundColor: '#0F0F0F' }}>
                <div style={{ display: 'flex', position: 'relative' }}>

                    <div style={{ display: 'flex', flex: 1, zIndex: 2, margin: '32px 16px 16px 16px' }}>
                        <div style={{ position: 'relative', height: '125px', aspectRatio: '1/1', borderRadius: '999px', alignSelf: 'center', margin: '0px 16px 16px 16px', border: '1px solid #474239', boxSizing: 'border-box' }}>
                            <img alt='' src={(() => {
                                try {
                                    if (!user.user || user.user.pfpurl === '') return require(`../../assets/pfp/${user.pfp}.png`)
                                    return user.user.pfpurl
                                } catch (e) {
                                    return require(`../../assets/pfp/no-texture.png`)
                                }
                            })()} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '999px' }} />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            <h3 style={{ margin: '8px 0px 0px 0px', color: 'white', fontSize: '20px', fontWeight: 300 }}>{user.displayName}</h3>
                            <i style={{ margin: '0px 0px 8px 0px', fontSize: '14px', color: signature.color }}>"{signature.text}"</i><br />
                            <small style={{ fontSize: '11px', justifySelf: 'center' }}>UID: {user.uid}</small>
                            <div style={{ display: 'inline-block', marginTop: '12px' }}>
                                {renderBadges}
                            </div>

                        </div>
                    </div>
                    {/* balls */}
                    <div style={{ position: 'absolute', right: '32px', top: '32px', bottom: '32px', display: 'flex', flexDirection: 'column', margin: 0, zIndex: 2, backgroundColor: '#00000077', border: '1px solid #474239', boxShadow: 'inset 0 0 20px 5px rgba(0, 0, 0, 0.4)', padding: '12px 20px 12px 20px', backdropFilter: 'blur(4px)', WebkitBackdropFilter: 'blur(4px)' }}>
                        <p style={{ margin: 0, fontSize: '14px', textShadow: '1px 1px 2px black' }}>Account Maturity: <b>Lvl {user.level}</b><span style={{ fontSize: '9px' }}>/70</span></p>
                        <p style={{ margin: 0, fontSize: '14px', textShadow: '1px 1px 2px black' }}>Unlocked Characters: <b>{user.totalChars ? user.totalChars : 0}</b><span style={{ fontSize: '9px' }}>/64</span></p>
                        <RefreshCooldown handleUserRefresh={() => handleUserRefresh()} lastUpdated={user.lastUpdated} loading={loading} />
                        <p style={{ margin: '4px 0px 0px 0px', fontSize: '10px', textShadow: '1px 1px 2px black' }}>Last Updated: {timeAgo(user.lastUpdated)}</p>
                    </div>
                    <img alt='' className="inventory-info--banner" src={(() => {
                        try {
                            if (!user.user || user.user.bannerurl === '') return require(`../../assets/default-banner.png`)
                            return user.user.bannerurl
                        } catch (e) {
                            return require(`../../assets/default-banner.png`)
                        }
                    })()} />
                </div>
            </div>
            {profileVersion !== null && (!user.version || user.version !== profileVersion) && <small style={{ fontSize: '18px', pointerEvents: 'none', zIndex: 10, marginBottom: '32px', textAlign: 'center' }}>⚠️ This profile is outdated (out of sync after major system changes). <b>Some features WILL NOT WORK AS EXPECTED.</b> Refresh profile to re-sync this account. ⚠️</small>}
        </>
    )
}

export default ProfileBanner
